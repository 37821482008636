<template>
	<div class="tcontainer">
        <div class="clearfix ttitle">
            <div class="text-success pull-right" style="margin-right: 6px; margin-top: 6px;">Total Due: &#8377;{{radiologist?.due_amount ? radiologist.due_amount : 0}}</div>
            <div class="title">My Received</div>
        </div>
        <DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit" :paginator="true" :rows="100" v-model:filters="filters" ref="dt"
            :totalRecords="totalRecords" :rowsPerPageOptions="[100,200,500,1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" filterDisplay="row"
            responsiveLayout="scroll">
            
            
            <Column field="id" header="Id" filterField="id" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Id"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['id']}}
                    </div>
                </template>
            </Column>
            <!-- <Column field="center_id" header="Center Id" filterField="center_id" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Center Id"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['center_id']}}
                    </div>
                </template>
            </Column> -->
            <Column field="amount" header="Amount" filterField="amount" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Amount"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        <b>{{data['amount']}}</b>
                    </div>
                </template>
            </Column>
            <!-- <Column field="is_direct" header="Is Direct" filterField="is_direct" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
                            <option value="">Select Is Direct</option>
                            
                            <option value="y">Y</option>
                            <option value="n">N</option>
                        </select>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['is_direct']}}
                    </div>
                </template>
            </Column> -->
            <Column field="mode" header="Mode" filterField="mode" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Mode"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['mode']}}
                    </div>
                </template>
            </Column>
            <Column field="transaction_id" header="Transaction Id" filterField="transaction_id" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Transaction Id"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['transaction_id']}}
                    </div>
                </template>
            </Column>
            <Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Created At"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        <!-- {{data['created_at']}} -->
                        <div class="tdata" style="width: 60px; margin: auto; font-weight: bold;">
                            {{format(data['created_at'], "DD/MM/YY h:mmA")}}
                        </div>
                    </div>
                </template>
            </Column>
            <!-- <Column header="Options">
                <template #body="{data}">
                    <div class="tdata options">
                        <button class="btn btn-sm sm-btn btn-warning" @click="showAddEdit(data)"><i class="fa fa-edit"></i></button>
                        <button class="btn btn-sm sm-btn btn-danger" @click="editId=data['id']; deleteModal.show();"><i class="fa fa-trash"></i></button>
                    </div>
                </template>
            </Column> -->
        </DataTable>

        
	</div>
</template>

<script>
export default {
    data(){
        return {
            loading: false,
            totalRecords: 0,
            tableRecords: null,
            lazyParams: {},
            radiologist: null,
            filters: {
                'id': {value: '', matchMode: 'like'},
                // 'center_id': {value: '', matchMode: 'like'},
                'amount': {value: '', matchMode: 'like'},
                // 'is_direct': {value: '', matchMode: 'like'},
                'mode': {value: '', matchMode: 'like'},
                'transaction_id': {value: '', matchMode: 'like'},
                'created_at': {value: '', matchMode: 'like'},
            },
        }
    },
    mounted() {
        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: null,
            sortOrder: null,
            filters: this.filters,
        };
        this.loadLazyData();
        this.get("radiologist/load-radiologist").then(res => {
            this.radiologist = res;
        });
    },
    methods: {
        loadLazyData(){
            this.loading = true;
            let params = JSON.parse(JSON.stringify(this.lazyParams));
            for(let f in params.filters){
                if(!params.filters[f].value.length) delete params.filters[f];
            }
            
            if(!params.sortField){
                params.sortField = "id";
                params.sortOrder = -1;
            }
            this.post("radiologist/load-payment-history", params).then(res => {
                this.loading = false;
                if(res.success){
                    this.totalRecords = res.totalRecords;
                    this.tableRecords = res.rows;
                }
            });
        },
        onPage(event){
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event){
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter(){
            this.lazyParams.filters = this.filters;
            this.loadLazyData();
        }
    }
}
</script>
<style scoped>
</style>
